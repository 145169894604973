// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_sj d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_sk d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_sl d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_sm d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_sn d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_sp d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_sq d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_sr x_sr";
export var heroExceptionNormal = "t_ss x_ss";
export var heroExceptionLarge = "t_st x_st";
export var Title1Small = "t_sv x_sv x_sH x_sJ";
export var Title1Normal = "t_sw x_sw x_sH x_sK";
export var Title1Large = "t_sx x_sx x_sH x_sL";
export var BodySmall = "t_sy x_sy x_sH x_s0";
export var BodyNormal = "t_sz x_sz x_sH x_s1";
export var BodyLarge = "t_sB x_sB x_sH x_s2";