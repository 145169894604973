// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "q_q6 d_bD";
export var datasheetRowWrapper = "q_lt d_lt d_bK";
export var datasheetLeftWrapper = "q_q7 d_bz d_bP";
export var datasheetWrapperFull = "q_q8 d_cD";
export var datasheetWrapperFullLeft = "q_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "q_mv d_lv";
export var contentWrapperCenter = "q_q9 d_lz";
export var datasheetLeftWrapperCenter = "q_rb d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "q_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "q_rc d_lw";
export var datasheetFooter = "q_ly d_ly";
export var alignLeft = "q_qh d_dv";
export var alignCenter = "q_bP d_dw";
export var alignRight = "q_qj d_dx";
export var datasheetInnerWrapperNewLeft = "q_rd d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "q_rf d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "q_rg d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "q_rh d_bK";
export var datasheetSubtitle = "q_rj d_cx";
export var tableRow = "q_rk";
export var cell = "q_rl";
export var tableRowWrapper = "q_rm d_w d_dv";
export var tableHeadingCell = "q_rn";
export var tableHeading = "q_rp";
export var tableRowStriped = "q_rq";
export var tableRowHeading = "q_rr";
export var dataTable = "q_rs d_w";
export var imageWrapper = "q_rt d_fg d_Z";
export var imageWrapperFull = "q_rv d_H d_w d_bf d_Z";
export var imageWrapperIcon = "q_rw";
export var titleMargin = "q_rx d_cs";
export var datasheetInnerInnerWrapper = "q_ry d_w";
export var hasLabels = "q_rz";
export var label = "q_rB";
export var SmallSmall = "q_rC x_rC x_sH x_s3";
export var SmallNormal = "q_rD x_rD x_sH x_s4";
export var SmallLarge = "q_rF x_rF x_sH x_s1";