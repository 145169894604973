// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_rS d_fp d_bG d_dv";
export var alignLeft = "s_qh d_fp d_bG d_dv";
export var alignDiscCenter = "s_rT d_fq d_bD d_dw";
export var alignCenter = "s_bP d_fq d_bD d_dw";
export var alignDiscRight = "s_rV d_fr d_bH d_dx";
export var alignRight = "s_qj d_fr d_bH d_dx";
export var footerContainer = "s_rW d_dW d_bW";
export var footerContainerFull = "s_rX d_dT d_bW";
export var footerHeader = "s_kf d_kf";
export var footerTextWrapper = "s_rY d_w";
export var footerDisclaimerWrapper = "s_km d_km d_ck";
export var badgeWrapper = "s_rZ d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "s_r0 d_bz d_bJ d_bN d_bL";
export var wide = "s_r1 d_cy";
export var right = "s_r2 d_bK";
export var line = "s_fk d_fl d_cv";
export var badgeDisclaimer = "s_r3 d_bC d_bP d_bJ";
export var badgeContainer = "s_r4 d_bz d_bH d_bP";
export var badge = "s_r5";
export var padding = "s_r6 d_c7";
export var end = "s_r7 d_bH";
export var linkWrapper = "s_r8 d_dB";
export var link = "s_mC d_dB";
export var colWrapper = "s_r9 d_cx";
export var consent = "s_f d_f d_bC d_bP";
export var disclaimer = "s_sb d_bz d_bJ";
export var media = "s_sc d_bx d_dy";
export var itemRight = "s_sd";
export var itemLeft = "s_sf";
export var itemCenter = "s_sg";
export var exceptionWeight = "s_sh x_s7";